import React from "react";

export function ExperienceContainer() {
    return (
        <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4">
                <div className="education wow fadeInRight" data-wow-delay="0.3s">
                    <ul className="timeline">
                        <li>
                            <i className="icon-graduation"></i>
                            <h2 className="timeline-title">Education</h2>
                        </li>
                        <li>
                            <div className="content-text">
                                <h3 className="line-title">Bsc - Drake University ('20)</h3>
                                <p className="line-text">Computer Science & Data Analytics</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="Skills wow fadeInRight" data-wow-delay="0.3s">
                    <ul className="timeline">
                        <li>
                            <i className="icon-settings"></i>
                            <h2 className="timeline-title">Skills & Certifications</h2>
                        </li>
                        <li>
                            <div className="content-text">
                                <h3 className="line-title">Certifications</h3>
                                <span />
                                <div className="line-text">
                                    <ul>
                                        <li>Associate of the Society of Actuaries (January 2022)</li>
                                        <li>Chartered Enterprise Risk Analyst (July 2023)</li>
                                        <li>
                                            AWS Certified Cloud Practitioner (<a href="https://www.credly.com/badges/edca0ee0-cd8c-4056-a327-2dce155e9000">Feb 2022</a>)
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="content-text">
                                <h3 className="line-title">Skills & Technology</h3>
                                <span />
                                <div className="line-text">
                                    <ul>
                                        <li>
                                            Highly proficient in Microsoft tools, including Excel and Power BI
                                        </li>
                                        <li>
                                            Creating cloud-based solutions with Amazon Web Services (AWS)
                                        </li>
                                        <li>
                                            Programming in Python, JavaScript/TypeScript, VBA, and C/C++
                                        </li>
                                        <li>
                                            Manipulating data using a variety of tools like R, SAS, and SQL
                                        </li>
                                        <li>
                                            Automation through RPA, web scraping, and creating APIs
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="education wow fadeInRight" data-wow-delay="0.3s">
                    <ul className="timeline">
                        <li>
                            <i className="icon-bulb"></i>
                            <h2 className="timeline-title">Interests</h2>
                        </li>
                        <li>
                            <div className="content-text">
                                <h3 className="line-title">Data & Analytics</h3>
                                <span />
                                <div className="line-text">
                                    <ul>
                                        <li>
                                            Storytelling & visualization
                                        </li>
                                        <li>
                                            Data preparation & analysis
                                        </li>
                                    </ul>
                                </div>
                                <br />
                                <h3 className="line-title">Automation</h3>
                                <span />
                                <div className="line-text">
                                    <ul>
                                        <li>
                                            ETL streamlining & automation
                                        </li>
                                        <li>
                                            Automated controls and workflows around processes
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-8">
                <div className="experience wow fadeInRight" data-wow-delay="0.6s">
                    <ul className="timeline">
                        <li>
                            <i className="icon-briefcase"></i>
                            <h2 className="timeline-title">Experience</h2>
                        </li>
                        <li>
                            <div className="content-text">
                                <h3 className="line-title">Actuarial Advisor - The Cigna Group</h3>
                                <span>Feb 2025 - Present</span>
                                <div className="line-text">
                                    <h4 className="line-title">U.S. Commercial Trend Analytics</h4>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="content-text">
                                <h3 className="line-title">Actuarial Lead Analyst - The Cigna Group</h3>
                                <span>Jan 2023 - Jan 2025</span>
                                <div className="line-text">
                                    <h4 className="line-title">Pharmacy Economics</h4>
                                    Supported and enabled members across our team by creating and maintaining
                                    a variety of data and analytical assets, while being a champion for process
                                    optimization and cloud-based solutions.
                                    <ul>
                                        <li>
                                            Owned several productionalized data sets and dashboards in tools including
                                            Databricks, Plotly Dash and Tableau
                                        </li>
                                        <li>
                                            Created a centralized repository to share standardized SQL queries across the department
                                            to align methodology and promote peer review of data pulls
                                        </li>
                                        <li>
                                            Eliminated dozens of hours of runtime on existing processes by streamlining workflows,
                                            optimizing execution, and eliminating technical debt
                                        </li>
                                        <li>
                                            Championed adoption of tools like Databricks, GitHub, Python and Tableau by
                                            leading trainings/demos and providing guidance for team members
                                        </li>
                                        <li>
                                            Created an inner-source Python package for reusable ETL and data analytics patterns to drive
                                            collaboration and scalability across various workflows
                                        </li>
                                        <li>
                                            Implemented automated data quality checks and governance across analytical assets and data pulls
                                        </li>
                                        <li>
                                            Created robust documentation around the team's processes, data sources, and available tools
                                            to encourage best practices and consistency for everyone
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="content-text">
                                <h3 className="line-title">Actuarial Associate - Principal Financial Group</h3>
                                <span>Sep 2021 - Jan 2023</span>
                                <div className="line-text">
                                    <h4 className="line-title">Cloud Platform Engineering</h4>
                                    Worked with a centralized team of
                                    engineers developing cloud pipelines and solutions
                                    for AWS data services (e.g. DynamoDB, Aurora Postgres,
                                    Athena/Glue/S3). Our focuses included:
                                    <ul>
                                        <li>
                                            Creating repeatable, scalable data solutions for use throughout the company
                                        </li>
                                        <li>
                                            Using Infrastructure-as-Code (IaC) for cloud deployment
                                        </li>
                                        <li>
                                            Providing internal guidance regarding security and best practices
                                        </li>
                                        <li>
                                            Exploring cloud data curation patterns within Snowflake
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="content-text">
                                <h3 className="line-title">Actuarial Assistant - Principal Financial Group</h3>
                                <span>May 2020 - Aug 2021</span>
                                <div className="line-text">
                                    <h4 className="line-title">General Account Investments</h4>
                                    <ul>
                                        <li>
                                            Created Python package for aggregating and manipulating MG-ALFA input data
                                        </li>
                                        <li>
                                            Implemented automated workflows for documentation and version control
                                        </li>
                                        <li>
                                            Created and analyzed financial projections using MG-ALFA
                                        </li>
                                        <li>
                                            Developed Power BI dashboard for cross-business reporting
                                        </li>
                                        <li>
                                            Performed ETL operations on financial projections using R
                                        </li>
                                        <li>
                                            Automated recurring data retrieval with web scraping
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

function Resume() {
    return (
        <div id="resume" className="section-padding-less">
            <div className="container resume">
                <div className="row">
                    <div style={{
                        marginLeft: '15%',
                        marginRight: '15%',
                        textAlign: 'center'
                    }}>
                        <h3 >Adam Lathan</h3>
                        {/* <h5 >Denver, CO</h5> */}
                        <h6>
                            <em>
                                Credentialed actuary with strong programming background
                                and a deep interest in process improvement,
                                automation, and modern data pipelines for actuarial
                                processes.
                            </em>
                        </h6>
                    </div>
                </div>
                <ExperienceContainer />
            </div>
        </div >
    )
}

export default Resume;
