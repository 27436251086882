import React from "react";
import { ExperienceContainer } from "./Resume"

const yrs_experience = new Date().getFullYear() - 2020;
const yrs_on_earth = new Date().getFullYear() - 1998;
const email_personal = 'lathan.adam@gmail.com'

function Home() {
    return (
        <React.Fragment>
            {/* Hero Area Start */}
            <section id="hero-area" className="hero-area-bg">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 text-center">
                            <div className="contents">
                                <h2 className="head-title wow fadeInUp" data-wow-delay="0.4s">Adam Lathan</h2>
                                <p className="script-font wow fadeInUp" data-wow-delay="0.6s">Developer and Actuary</p>
                                <ul className="social-icon wow fadeInUp" data-wow-delay="0.8s">
                                    <li>
                                        <a className="linkedin" href="https://linkedin.com/in/adam-lathan-actuary"><i className="icon-social-linkedin"></i></a>
                                    </li>
                                    <li>
                                        <a className="github" href="https://github.com/lathaniel"><i className="icon-social-github"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Hero Area End */}

            {/* About section */}
            <section id="about" className="section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div className="img-thumb wow fadeInLeft" data-wow-delay="0.3s">
                                <img className="img-fluid" src="assets/img/about/headshot-1.jpg" alt="Adam's headshot" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div className="profile-wrapper wow fadeInRight" data-wow-delay="0.3s">
                                <h3>Hello!</h3>
                                <p>
                                    My name is Adam Lathan. I've been working full-time as an actuary for {yrs_experience} years.
                                    I thrive in environments that foster process modernization and automation, bridging the gap between
                                    actuarial/finance needs and IT capabilities. More info about my strengths and interests can be found
                                    further along on this page.
                                </p>
                                <div className="about-profile">
                                    <ul className="admin-profile">
                                        <li><span className="pro-title"> Name </span> <span className="pro-detail">Adam Lathan</span></li>
                                        <li><span className="pro-title"> Age </span> <span className="pro-detail">{yrs_on_earth}</span></li>
                                        <li><span className="pro-title"> Experience </span> <span className="pro-detail">{yrs_experience} Years</span></li>
                                        <li><span className="pro-title"> Country </span> <span className="pro-detail">USA</span></li>
                                        <li><span className="pro-title"> Location </span> <span className="pro-detail">Denver, CO</span></li>
                                        <li><span className="pro-title"> e-mail </span> <span className="pro-detail">{email_personal}</span></li>
                                    </ul>
                                </div>
                                <a href="/resume" className="btn btn-common"><i className="icon-paper-clip"></i> Printable Resume</a>
                                <a href="#contact" className="btn btn-danger"><i className="icon-speech"></i> Contact Me</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Services section */}
            <section id="services" className="services section-padding">
                <h2 className="section-title wow flipInX" data-wow-delay="0.4s">Interests & Strengths</h2>
                <div className="container">
                    <div className="row">
                        {/* Services item */}
                        <div className="col-md-6 col-lg-3 col-xs-12">
                            <div className="services-item wow fadeInDown" data-wow-delay="0.3s">
                                <div className="icon">
                                    <i className="icon-calculator"></i>
                                </div>
                                <div className="services-content">
                                    {
                                        // eslint-disable-next-line
                                    }<h3><a>Actuarial Analytics</a></h3>
                                </div>
                            </div>
                        </div>
                        {/* Services item */}
                        <div className="col-md-6 col-lg-3 col-xs-12">
                            <div className="services-item wow fadeInDown" data-wow-delay="0.6s">
                                <div className="icon">
                                    <i className="icon-layers"></i>
                                </div>
                                <div className="services-content">
                                    {
                                        // eslint-disable-next-line
                                    }<h3><a>Software Development</a></h3>
                                </div>
                            </div>
                        </div>
                        {/* Services item */}
                        <div className="col-md-6 col-lg-3 col-xs-12">
                            <div className="services-item wow fadeInDown" data-wow-delay="0.6s">
                                <div className="icon">
                                    <i className="icon-settings"></i>
                                </div>
                                <div className="services-content">
                                    {
                                        // eslint-disable-next-line 
                                    }<h3><a>Automation</a></h3>
                                </div>
                            </div>
                        </div>
                        {/* Services item */}
                        <div className="col-md-6 col-lg-3 col-xs-12">
                            <div className="services-item wow fadeInDown" data-wow-delay="0.9s">
                                <div className="icon">
                                    <i className="icon-chart"></i>
                                </div>
                                <div className="services-content">
                                    {
                                        // eslint-disable-next-line
                                    }<h3><a>Predictive Modeling</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Resume Section */}
            <div id="resume" className="section-padding">
                <div className="container">
                    <ExperienceContainer />
                </div>
            </div>

            {/*Counter Area Start*/}
            <section className="counter-section section-padding">
                <div className="container">
                    <div className="row" style={{ justifyContent: 'center' }}>
                        <div className="col-md-3 col-sm-6 work-counter-widget text-center">
                            <div className="counter wow fadeInDown" data-wow-delay="1.2s">
                                <div className="icon"><i className="icon-check"></i></div>
                                <div className="noCounter">ASA</div>
                                <p>Associate of the Society of Actuaries</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 work-counter-widget text-center">
                            <div className="counter wow fadeInDown" data-wow-delay="1.2s">
                                <div className="icon"><i className="icon-check"></i></div>
                                <div className="noCounter">CERA</div>
                                <p>Chartered Enterprise Risk Analyst</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 work-counter-widget text-center">
                            <div className="counter wow fadeInDown" data-wow-delay="1.2s">
                                <div className="icon"><i className="icon-check"></i></div>
                                <div className="noCounter">AWS Certified</div>
                                <p><a className="hyperlink-dark-bg" href="https://www.credly.com/badges/edca0ee0-cd8c-4056-a327-2dce155e9000">Certified Cloud Practitioner</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*Contact Section Start */}
            <section id="contact" className="section-padding">
                <div className="contact-form">
                    <div className="container">
                        <div className="row contact-form-area wow fadeInUp" data-wow-delay="0.4s">
                            {/* <div className="col-md-6 col-lg-6 col-sm-12">
                                <div className="contact-block">
                                    <h2>Contact Form</h2>
                                    <form id="contactForm">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" id="name" name="name" placeholder="Name" required data-error="Please enter your name" />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" placeholder="Email" id="email" className="form-control" name="email" required data-error="Please enter your email" />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <input type="text" placeholder="Subject" id="msg_subject" className="form-control" required data-error="Please enter your subject" />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <textarea className="form-control" id="message" placeholder="Your Message" rows="5" data-error="Write your message" required></textarea>
                                                    <div className="help-block with-errors"></div>
                                                </div>
                                                <div className="submit-button">
                                                    <button className="btn btn-common" id="submit" type="submit">Send Message</button>
                                                    <div id="msgSubmit" className="h3 text-center hidden"></div>
                                                    <div className="clearfix"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div> */}
                            <div className="col-md-6 col-lg-6 col-sm-12">
                                <div className="footer-right-area wow fadeIn">
                                    <h2>Contact Info</h2>
                                    <div className="footer-right-contact">
                                        <div className="single-contact">
                                            <div className="contact-icon">
                                                <i className="fa fa-map-marker"></i>
                                            </div>
                                            <p>Denver, CO</p>
                                        </div>
                                        <div className="single-contact">
                                            <div className="contact-icon">
                                                <i className="fa fa-envelope"></i>
                                            </div>
                                            <p><a href={`mailto:${email_personal}`}>{email_personal}</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Google maps thing */}
                            {/* <div className="col-md-12">
                                <object style={{ border: 0, height: '450px', width: '100%' }} data="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d34015.943594576835!2d-106.43242624069771!3d31.677719472407432!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86e75d90e99d597b%3A0x6cd3eb9a9fcd23f1!2sCourtyard+by+Marriott+Ciudad+Juarez!5e0!3m2!1sen!2sbd!4v1533791187584" aria-label="map"></object>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment >
    )
}

export default Home;
